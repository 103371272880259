<template>
  <div id="homepage-1">
    <div
      class="ps-container shop-direct"
      style="
        display: none;
        background-color: white;
        padding: 20px;
        color: black;
        top: 0;
        width: 100%;
        right: 0;
      "
    >
      <div class="container">
        <h5 class="banner-heading">
          <span class="banner-heading--1">Shop DIRECTLY from</span>
          <span class="banner-heading--2">Zimbabwe's MERCHANTS</span>
        </h5>
        <h4 class="mt-2 banner-heading banner-title">
          INSTANT or SCHEDULED pickup or delivery across Zimbabwe.
        </h4>
      </div>
      <!-- <a class="ps-btn" href="#">ShopNowPH</a> -->
    </div>
    <div class="ps-home-banner ps-home-banner--1" style="display: none">
      <section class="ps-container">
        <SearchForm></SearchForm>
        
      </section>
    </div>
    

    <img
      class="ls-full-width"
      v-lazy="'/img/featured brands.png'"
      alt="Featured Brands"
    />
    <div class="row">
      <div class="col-12 col-lg-6">
        <HomeSlideshowFeaturedBrands></HomeSlideshowFeaturedBrands>
      </div>
      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="col-12 col-lg-12">
            <div class="row home-shop-header justify-content-between mx-n2" v-if="colcom">
              <div class="">
                <h4 class="text-left font-weight-bold" style="font-size: 2rem;">{{ colcom.name }}</h4>
              </div>
              <div class="">
                <p class="go-to-store-button" style="text-align: right">
                  <router-link
                    :to="{ name: 'shop', params: { slug: colcom.slug } }"
                    style="text-wrap: nowrap; font-weight: bold;"
                    >Go to Shop ></router-link
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6" v-for="cProduct in colcomProducts">
                <router-link
                  :to="{ name: 'Product', params: { slug: cProduct.slug } }"
                  class="ls-product-card"
                >
                  <img v-lazy="cProduct.images[0].path" />
                  <h4>{{ cProduct.name }}</h4>
                  <p>${{ cProduct.price }}</p>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-12">
            <div class="row home-shop-header justify-content-between mx-n2" v-if="butcherbox">
              <div class="">
                <h4 class="text-left font-weight-bold" style="font-size: 2rem;">{{ butcherbox.name }}</h4>
              </div>
              <div class="">
                <p class="go-to-store-button" style="text-align: right">
                  <router-link
                    :to="{ name: 'shop', params: { slug: butcherbox.slug } }"
                    style="text-wrap: nowrap; font-weight: bold;"
                    >Go to Shop ></router-link
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6" v-for="bProduct in butcherboxProducts">
                <router-link
                  :to="{ name: 'Product', params: { slug: bProduct.slug } }"
                  class="ls-product-card"
                >
                  <img v-lazy="bProduct.images[0].path" />
                  <h4>{{ bProduct.name }}</h4>
                  <p>${{ bProduct.price }}</p>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-12">
            <div class="row home-shop-header justify-content-between mx-n2" v-if="greenwood">
              <div class="">
                <h4 class="text-left font-weight-bold" style="font-size: 2rem;">GreenWood Pharmacy</h4>
              </div>
              <div class="">
                <p class="go-to-store-button" style="text-align: right">
                  <router-link
                    :to="{ name: 'shop', params: { slug: greenwood.slug } }"
                    style="text-wrap: nowrap; font-weight: bold;"
                    >Go to Shop ></router-link
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6" v-for="greenProduct in greenwoodProducts">
                <router-link
                  :to="{ name: 'Product', params: { slug: greenProduct.slug } }"
                  class="ls-product-card"
                >
                  <img v-lazy="greenProduct.images[0].path" />
                  <h4>{{ greenProduct.name }}</h4>
                  <p>${{ greenProduct.price }}</p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" id="hot_products">
      
      <!-- <div class="col-12 ls-center">
        <h1>Hot Products</h1>
      </div> -->
      <!-- <div class="col-4 col-sm-4 col-md-3 col-lg-3 ls-center ls-hover-shadow">
        <router-link :to="{ name: 'dial_from_263' }" class="ls-hover-shadow"
          ><img v-lazy="'/img/DialFrom263.svg'" alt="DialFrom263"
        /></router-link>
      </div> -->
      <!-- <div class="col-4 col-sm-4 col-md-3 col-lg-3 ls-center ls-hover-shadow">
        <router-link class="ls-hover-shadow" :to="{ name: 'runner_requests' }"
          ><img v-lazy="'/img/E-Runner.svg'" alt="E-Runner"
        /></router-link>
      </div> -->
      <!-- <div class="col-4 col-sm-4 col-md-3 col-lg-3 ls-center">
        <router-link class="ls-hover-shadow" :to="{ name: 'topup_now' }"
          ><img v-lazy="'/img/TopUpSmart.svg'" alt="TopUpSmart"
        /></router-link>
      </div> -->
      <!-- <div class="col-4 col-sm-4 col-md-3 col-lg-3 ls-center">
        <router-link class="ls-hover-shadow" :to="{ name: 'shop-now' }"
          ><img v-lazy="'/img/ShopNow.svg'" alt="ShopNow"
        /></router-link>
      </div> -->
      <!-- <div class="col-4 col-sm-4 col-md-3 col-lg-3 ls-center">
        <router-link :to="{ name: 'simple_pay_direct' }" class="ls-hover-shadow"
          ><img v-lazy="'/img/SimplePayDirect.svg'" alt="SimplePayDirect"
        /></router-link>
      </div> -->
      
      <img
      class="ls-full-width"
      v-lazy="'/img/hot-products.jpg'"
      alt="Hot Products"
    />

    <img
      class="ls-full-width"
      v-lazy="'/img/runner locator.jpg'"
      alt="Runner Locator"
    />

      <HeaderSearch2></HeaderSearch2>
   
    
    <!-- <img
      class="ls-full-width"
      v-lazy="'/img/black-layer.jpg'"
      alt="Black Layer"
    />
       -->
     
      <!-- <div class="col-4 col-sm-4 col-md-3 col-lg-3 ls-center">
        <router-link
          class="ls-hover-shadow"
          :to="{
            name: 'shop',
            params: { slug: 'pahukama-building-materials' },
          }"
          ><img v-lazy="'/img/Building Materials.svg'" alt="Building Materials"
        /></router-link>
      </div> -->
      <!-- <div class="col-4 col-sm-4 col-md-3 col-lg-3 ls-center">
        <router-link
          class="ls-hover-shadow"
          :to="{ name: 'shop', params: { slug: 'pahukama-hardware' } }"
          ><img v-lazy="'/img/Hardware.svg'" alt="Hardware"
        /></router-link>
      </div> -->
      <!-- <div class="col-4 col-sm-4 col-md-3 col-lg-3 ls-center">
        <router-link
          class="ls-hover-shadow"
          :to="{ name: 'specialist_requests' }"
          ><img
            v-lazy="'/img/Pahukama Specialist.svg'"
            alt="Pahukama Specialist"
        /></router-link>
      </div> -->
      <!-- <div class="col-4 col-sm-4 col-md-3 col-lg-3 ls-center">
        <router-link
          class="ls-hover-shadow"
          :to="{ name: 'shop', params: { slug: 'pahukama-treats' } }"
          ><img
            v-lazy="'/img/Treats and takeaways.svg'"
            alt="Treats and takeaways"
        /></router-link>
      </div> -->
    </div>
    <!-- <div style="margin-bottom: -15px;">
      <HomeSlideshowFeaturedBrands2></HomeSlideshowFeaturedBrands2>

    </div> -->
    
    <img
      class="ls-full-width"
      v-lazy="'/img/easy_eshop.jpg'"
      alt="Easy E Shop"
    />

    <img src="https://pahukama-onestopshop.s3.amazonaws.com/logos-banners-products-services/onestopshop.jpg" alt="">

    <div class="col-12 col-lg-12 mt-2" id="grocery">
            <div class="row home-shop-header justify-content-between" v-if="grocery">
              <div class="">
                <h5 class="text-left" style="font-size: 2rem;">Grocery Store</h5>
                <h6>Lowest USD prices</h6>
              </div>
              <div class="">
                <p class="go-to-store-button" style="text-align: right">
                  <router-link
                    :to="{ name: 'shop', params: { slug: grocery.slug } }"
                    style="text-wrap: nowrap; font-weight: bold;"
                    >Go to Shop ></router-link
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6" v-for="groProduct in groceryProducts">
                <a :href="'/product/' + groProduct.slug" class="ls-product-card">
            <img v-lazy="groProduct.images[0].path" />
            <h4>{{ groProduct.name }}</h4>
            <p>${{ groProduct.price }}</p>
          </a>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-12" id="liquor">
            <div class="row home-shop-header justify-content-between" v-if="bottle">
              <div class="">
                <h5 class="text-left" style="font-size: 2rem;">Bottle Store</h5>
              </div>
              <div class="">
                <p class="go-to-store-button" style="text-align: right">
                  <router-link
                    :to="{ name: 'shop', params: { slug: bottle.slug } }"
                    style="text-wrap: nowrap; font-weight: bold;"
                    >Go to Shop ></router-link
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6" v-for="btProduct in bottleProducts">
                <a :href="'/product/' + btProduct.slug" class="ls-product-card">
            <img v-lazy="btProduct.images[0].path" />
            <h4>{{ btProduct.name }}</h4>
            <p>${{ btProduct.price }}</p>
          </a>
              </div>
            </div>
          </div>

          

          <div class="col-12 col-lg-12" id="takeaway">
            <div class="row home-shop-header justify-content-between" v-if="tkaway">
              <div class="">
                <h5 class="text-left" style="font-size: 2rem;">Takeaways</h5>
              </div>
              <div class="">
                <p class="go-to-store-button" style="text-align: right">
                  <router-link
                    :to="{ name: 'shop', params: { slug: tkaway.slug } }"
                    style="text-wrap: nowrap; font-weight: bold;"
                    >Go to Shop ></router-link
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6" v-for="tkProduct in tkawayProducts">
                <a :href="'/product/' + tkProduct.slug" class="ls-product-card">
                <img v-lazy="tkProduct.images[0].path" />
                <h4>{{ tkProduct.name }}</h4>
                <p>${{ tkProduct.price }}</p>
              </a>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-12" id="butcher">
            <div class="row home-shop-header justify-content-between" v-if="butchery">
              <div class="">
                <h5 class="text-left" style="font-size: 2rem;">Butchery</h5>
              </div>
              <div class="">
                <p class="go-to-store-button" style="text-align: right">
                  <router-link
                    :to="{ name: 'shop', params: { slug: butchery.slug } }"
                    style="text-wrap: nowrap; font-weight: bold;"
                    >Go to Shop ></router-link
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6" v-for="buProduct in butcheryProducts">
                <a :href="'/product/' + buProduct.slug" class="ls-product-card">
                  <img v-lazy="buProduct.images[0].path" />
                  <h4>{{ buProduct.name }}</h4>
                  <p>${{ buProduct.price }}</p>
                </a>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-12">
            <div class="row home-shop-header justify-content-between" v-if="greens">
              <div class="">
                <h5 class="text-left" style="font-size: 2rem;">Greens Market</h5>
              </div>
              <div class="">
                <p class="go-to-store-button" style="text-align: right">
                  <router-link
                    :to="{ name: 'shop', params: { slug: greens.slug } }"
                    style="text-wrap: nowrap; font-weight: bold;"
                    >Go to Shop ></router-link
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6" v-for="grProduct in greensProducts">
                <a :href="'/product/' + grProduct.slug" class="ls-product-card">
                <img v-lazy="grProduct.images[0].path" />
                <h4>{{ grProduct.name }}</h4>
                <p>${{ grProduct.price }}</p>
              </a>
              </div>
            </div>
          </div>

          <div id="treats" class=" pt-4 col-12 col-lg-12">
            <div class="row home-shop-header justify-content-between" v-if="treats">
              <div class="">
                <h5 class="text-left" style="font-size: 2rem;">Treats & Special Events</h5>
              </div>
              <div class="">
                <p class="go-to-store-button" style="text-align: right">
                  <router-link
                    :to="{ name: 'shop', params: { slug: treats.slug } }"
                    style="text-wrap: nowrap; font-weight: bold;"
                    >Go to Shop ></router-link
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6" v-for="treProduct in treatsProducts">
                <a :href="'/product/' + treProduct.slug" class="ls-product-card">
              <img v-lazy="treProduct.images[0].path" />
              <h4>{{ treProduct.name }}</h4>
              <p>${{ treProduct.price }}</p>
            </a>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-12" id="building">
            <div class="row home-shop-header justify-content-between" v-if="building">
              <div class="">
                <h5 class="text-left" style="font-size: 2rem;">Building Materials</h5>
              </div>
              <div class="">
                <p class="go-to-store-button" style="text-align: right">
                  <router-link
                    :to="{ name: 'shop', params: { slug: building.slug } }"
                    style="text-wrap: nowrap; font-weight: bold;"
                    >Go to Shop ></router-link
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6" v-for="buildProduct in buildingProducts">
                <a :href="'/product/' + buildProduct.slug" class="ls-product-card">
                  <img v-lazy="buildProduct.images[0].path" />
                  <h4>{{ buildProduct.name }}</h4>
                  <p>${{ buildProduct.price }}</p>
                </a>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-12">
            <div class="row home-shop-header justify-content-between" v-if="hardware">
              <div class="" style="width: 60%;">
                <h5 class="text-left" style="font-size: 2rem;">{{ hardware.name }}</h5>
              </div>
              <div class="">
                <p class="go-to-store-button" style="text-align: right">
                  <router-link
                    :to="{ name: 'shop', params: { slug: hardware.slug } }"
                    style="text-wrap: nowrap; font-weight: bold;"
                    >Go to Shop ></router-link
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6" v-for="hProduct in hardwareProducts">
                <a :href="'/product/' + hProduct.slug" class="ls-product-card">
                <img v-lazy="hProduct.images[0].path" />
                <h4>{{ hProduct.name }}</h4>
                <p>${{ hProduct.price }}</p>
              </a>
              </div>
            </div>

          </div>

          <div class="col-12 col-lg-12">
            <div class="row home-shop-header justify-content-between" v-if="bakery">
              <div class="">
                <h5 class="text-left" style="font-size: 2rem;">Bakery</h5>
              </div>
              <div class="">
                <p class="go-to-store-button" style="text-align: right">
                  <router-link
                    :to="{ name: 'shop', params: { slug: bakery.slug } }"
                    style="text-wrap: nowrap; font-weight: bold;"
                    >Go to Shop ></router-link
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6" v-for="bkProduct in bakeryProducts">
                <a :href="'/product/' + bkProduct.slug" class="ls-product-card">
              <img v-lazy="bkProduct.images[0].path" />
              <h4>{{ bkProduct.name }}</h4>
              <p>${{ bkProduct.price }}</p>
            </a>
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-3" v-for="grnProduct in greensProducts">
                <router-link
                  :to="{ name: 'Product', params: { slug: grnProduct.slug } }"
                  class="ls-product-card"
                >
                  <img v-lazy="grnProduct.images[0].path" />
                  <h4>{{ grnProduct.name }}</h4>
                  <p>${{ grnProduct.price }}</p>
                </router-link>
              </div>
            </div> -->
          </div>

          <div class="col-12 col-lg-12">
            <div class="row home-shop-header justify-content-between" v-if="butcherbox">
              <div class="">
                <h5 class="text-left" style="font-size: 2rem;">Pahukama Township</h5>
              </div>
              <div class="">
                <p class="go-to-store-button" style="text-align: right">
                  <router-link
                    :to="{ name: 'shop', params: { slug: butcherbox.slug } }"
                    style="text-wrap: nowrap; font-weight: bold;"
                    >Go to Shop ></router-link
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6" v-for="bProduct in butcherboxProducts">
                <a :href="'/product/' + bProduct.slug" class="ls-product-card">
                  <img v-lazy="bProduct.images[0].path" />
                  <h4>{{ bProduct.name }}</h4>
                  <p>${{ bProduct.price }}</p>
                </a>
              </div>
            </div>
          </div>

          <div v-if="isHome">
            <HomeSlideshow></HomeSlideshow>
          </div>

    



    <div id="featured_brands"></div>
    <div class="ls-full-width" id="we_deliver">
      <img v-lazy="'/img/we deliver banner.jpg'" alt="We deliver Banner" />
    </div>

    <div class="row align-items-center" id="recharge_dial_from_263">
      <div class="col-9 col-xs-10">
        <div class="ls-center">
          <h1>Recharge your <b>DialFrom+263</b> bundles from here</h1>
          <router-link :to="{ name: 'dial_from_263' }">
            <button class="btn btn-primary ls-submit-button">
              Recharge Here
            </button>
          </router-link>
        </div>
      </div>
      <div class="col-3 col-xs-2" style="text-align: right">
        <img v-lazy="'/img/dialfrom263.png'" alt="DialFrom263" />
      </div>
    </div>
    <div id="search_bar"></div>

    <div class="row ls-hero-banner" id="home_hero" >
      <div class="col-12 ls-center">
        <img class="" style="margin-top: -79px" v-lazy="'/img/logo.png'" alt="logo" />
      </div>
      <div class="col-12 ls-center">
        <h1 style="margin-top: -18px;">One Stop Zimbabwe!</h1>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-md-2 col-2"></div>
          <div class="col-md-8 col-12">
            <HeaderSearch style="margin-top: -10px;"></HeaderSearch>
          </div>
          <div class="col-md-2 col-2"></div>
        </div>
      </div>
    </div>

    <div
      v-if="!loading && firstCategories.length > 0 && false"
      class="ps-home-categories"
    >
      <div class="ps-container">
        <div class="ps-section__header">
          <h1>Shop by category from manufacturers, wholesalers, retailers</h1>
        </div>
        <div class="card-section mb-3">
          <div class="card">
            <ShopProductCard
              :shop-categories="secondCategories"
              :active="hasCategories(secondCategories)"
            ></ShopProductCard>
          </div>
        </div>
        <div class="card-section">
          <div class="card-container">
            <ShopCategoryCard
              v-for="shopCategory in firstCategories"
              :key="shopCategory.id"
              :shop-category="shopCategory"
            ></ShopCategoryCard>
          </div>
        </div>
      </div>
    </div>

    <ShopProductCard
      v-if="false"
      :shop-categories="firstCategories"
      :active="hasCategories(firstCategories)"
    ></ShopProductCard>

    <div v-if="loading && false" class="container d-flex align-items-center">
      <strong>Loading...</strong>
      <div
        class="spinner-border ml-auto"
        role="status"
        aria-hidden="true"
      ></div>
    </div>

    <div
      v-if="!loading && secondCategories.length > 0 && false"
      class="ps-home-categories"
    >
      <div class="ps-container">
        <div class="card-section">
          <div class="card-container">
            <ShopCategoryCard
              v-for="shopCategory in secondCategories"
              :key="shopCategory.id"
              :shop-category="shopCategory"
            ></ShopCategoryCard>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading && false" class="container d-flex align-items-center">
      <strong>Loading...</strong>
      <div
        class="spinner-border ml-auto"
        role="status"
        aria-hidden="true"
      ></div>
    </div>

    <div
      v-if="!loading && thirdCategories.length > 0 && false"
      class="ps-home-categories"
    >
      <div class="ps-container">
        <div class="card-section">
          <div class="card-container">
            <ShopCategoryCard
              v-for="shopCategory in thirdCategories"
              :key="shopCategory.id"
              :shop-category="shopCategory"
            ></ShopCategoryCard>
          </div>
        </div>
      </div>
    </div>

    <ShopProductCard
      v-if="false"
      :shop-categories="thirdCategories"
      :active="hasCategories(thirdCategories)"
    ></ShopProductCard>

    <div v-if="loading && false" class="container d-flex align-items-center">
      <strong>Loading...</strong>
      <div
        class="spinner-border ml-auto"
        role="status"
        aria-hidden="true"
      ></div>
    </div>

    <div class="ls-section" v-if="false">
      <h5 class="ls-center-text">
        Why send remittances to next of kin as goods and services bought
        directly from merchant & why work with Pahukama trusted runners
      </h5>
    </div>
    <div class="ps-site-features" v-if="false">
      <div class="ps-container">
        <div class="ps-block--site-features">
          <div class="ps-block__item">
            <div class="ps-block__left"><i class="icon-rocket"></i></div>
            <div class="ps-block__right">
              <h4>Safety & Security</h4>
              <p>
                Every transaction on our platform is secured by trusted
                technology to protect our customers.
              </p>
            </div>
          </div>
          <div class="ps-block__item">
            <div class="ps-block__left"><i class="icon-sync"></i></div>
            <div class="ps-block__right">
              <h4>Frictionless logistics</h4>
              <p>
                We are building an inclusive digital retail ecosystem accessible
                countrywide.
              </p>
            </div>
          </div>
          <div class="ps-block__item">
            <div class="ps-block__left"><i class="icon-credit-card"></i></div>
            <div class="ps-block__right">
              <h4>e-Marketplace Convenience</h4>
              <p>
                Biggest all categories One stop shop accepting secure varied
                payments in both US$ and RTGS
              </p>
            </div>
          </div>
          <div class="ps-block__item">
            <div class="ps-block__left"><i class="icon-rocket"></i></div>
            <div class="ps-block__right">
              <h4>Urban-Rural Contactless Delivery</h4>
              <p>
                Countrywide coverage including rural areas with flexible
                guaranteed Pick up and Delivery options
              </p>
            </div>
          </div>
          <div class="ps-block__item">
            <div class="ps-block__left"><i class="icon-gift"></i></div>
            <div class="ps-block__right">
              <h4>Peace of Mind</h4>
              <p>
                Buy direct from merchants and trusted task rabbits and receive
                order processing & updates
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row align-items-center"
      id="footer"
      style="background-color: #f8f8f8; padding: 20px 20px"
    >
      <div class="col-sm-12 col-4"></div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
        <div class="ps-block__content">
          <h3>Get Pahukama Apps</h3>
          <p>
            Mobile apps here for placing orders anytime, anyplace and any
            device.
          </p>
          <form class="ps-form--download-app" hidden method="post">
            <div class="form-group--nest">
              <input
                class="form-control"
                type="Email"
                placeholder="Email Address"
              />
              <button class="ps-btn">Subscribe</button>
            </div>
          </form>
        </div>
      </div>

      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
        <div class="row">
          <div class="col-6">
            <a href="#"
              ><img v-lazy="'/img/google-play.png'" alt="google-play store"
            /></a>
          </div>
          <div class="col-6">
            <a href="#"
              ><img v-lazy="'/img/app-store.png'" alt="Apple App Store"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import Vue from "vue";
import HomeSlideshowFeaturedBrands from "../../../components/HomeSlideshowFeaturedBrands";
import HomeSlideshowFeaturedBrands2 from "../../../components/HomeSlideshowFeaturedBrands2";

import ShopCategoryCard from "../../../components/Cards/ShopCategoryCard";
import ShopProductCard from "../../../components/ShopProductCard";
import HomeSlideshow from "../../../components/HomeSlideshow";
import SearchForm from "../../../components/SearchForm";
import HeaderSearch from "../../../components/HeaderSearch";
import HeaderSearch2 from "../../../components/HeaderSearch2";

export default {
  name: "Home",
  components: {
    SearchForm,
    HomeSlideshow,
    ShopProductCard,
    ShopCategoryCard,
    HeaderSearch,
    HeaderSearch2,
    HomeSlideshowFeaturedBrands,
    HomeSlideshowFeaturedBrands2,
    
  },
  data() {
    return {
      firstCategories: [],
      secondCategories: [],
      thirdCategories: [],
      searchMethod: "Shop Location",
      searchQuery: "",
      loading: true,
      isHome: true,
      shops: [],
      shopOne: null,
      shopOneProducts: null,
      shopTwo: null,
      shopTwoProducts: null,
      shopThree: null,
      shopThreeProducts: [],
      shopFour: null,
      shopFourProducts: [],

      butcherbox: null,
      butcherboxProducts: [],
      colcom: null,
      colcomProducts: [],
      hardware: null,
      hardwareProducts: [],
      treats: null,
      treatsProducts: [],
      grocery: null,
      groceryProducts: [],
      mdepot: null,
      mdepotProducts: [],
      township: null,
      townshipProducts: [],
      building: null,
      buildingProducts: [],
      bakery: null,
      bakeryProducts: [],
      greens: null,
      greensProducts: [],
      tkaway: null,
      tkawayProducts: [],
      bottle: null,
      bottleProducts: [],
      greenwood: null,
      greenwoodProducts: [],
      butchery: null,
      butcheryProducts: [],
    };
  },
  computed: {
    ...mapState({
      cart: (state) => state.cart,
      auth: (state) => state.auth,
      shop: (state) => state.shop,
    }),
  },
  mounted() {
    var that = this;
    this.getShops();
  },
  methods: {
    hasCategories(categories) {
      if (categories.length > 0) {
        return categories[0].length > 0;
      }
      return false;
    },

    getShops() {
      this.shops.length = 0;
      this.$loading(true);

      axios
        .get("shops/list")
        .then((response) => {
          this.shops = response.data;
          let limit = 6;
          let limit2 = 2;

          this.shops.forEach((shop) => {
            if (shop.id == 10) {
              this.colcom = shop;
              this.colcomProducts = this.getShopProducts(shop, limit2);
            }

            if (shop.id == 7) {
              this.hardware = shop;
              this.hardwareProducts = this.getShopProducts(shop, limit2);
            }

            if (shop.id == 4) {
              this.bakery = shop;
              this.bakeryProducts = this.getShopProducts(shop, limit2);
            }

            if (shop.id == 6) {
              this.greens = shop;
              this.greensProducts = this.getShopProducts(shop, limit2);
            }

            if (shop.id == 9) {
              this.tkaway = shop;
              this.tkawayProducts = this.getShopProducts(shop, limit2);
            }

            if (shop.id == 3) {
              this.butchery = shop;
              this.butcheryProducts = this.getShopProducts(shop, limit2);
            }



            if (shop.id == 1) {
              this.greenwood = shop;
              this.greenwoodProducts = this.getShopProducts(shop, limit2);
            }

            if (shop.id == 12) {
              this.building = shop;
              this.buildingProducts = this.getShopProducts(shop, limit2);
            }

            if (shop.id == 2) {
              this.grocery = shop;
              this.groceryProducts = this.getShopProducts(shop, limit2);
            }

            if (shop.id == 5) {
              this.bottle = shop;
              this.bottleProducts = this.getShopProducts(shop, limit2);
            }

            if (shop.id == 13) {
              this.township = shop;
              this.townshipProductsProducts = this.getShopProducts(shop, limit2);
            }

            if (shop.id == 8) {
              this.treats = shop;
              this.treatsProducts = this.getShopProducts(shop, limit2);
            }
            if (shop.id == 11) {
              this.butcherbox = shop;
              this.butcherboxProducts = this.getShopProducts(shop, limit2);
            }
          });

          //alert(JSON.stringify("shops length = " + this.shops.length));
          if (this.shops.length > 0) {
            this.shopOne = this.shops[0];
            if (this.shopOne) {
              limit = this.shops.length === 1 ? 8 : 4;
              this.shopOneProducts = this.getShopProducts(this.shopOne, limit);
              //alert(JSON.stringify(this.shopOneProducts));
            }
          }
          if (this.shops.length > 1) {
            this.shopTwo = this.shops[1];
            if (this.shopTwo) {
              this.shopTwoProducts = this.getShopProducts(this.shopTwo, limit);
            }
          }
          if (this.shops.length > 2) {
            this.shopThree = this.shops[2];
            if (this.shopThree) {
              this.shopThreeProducts = this.getShopProducts(
                this.shopThree,
                limit
              );
            }
          }
          if (this.shops.length > 3) {
            this.shopFour = this.shops[2];
            if (this.shopFour) {
              this.shopFourProducts = this.getShopProducts(
                this.shopFour,
                limit
              );
            }
          }
          this.$loading(false);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          this.$loading(false);
        });
    },

    getShopProducts(shop, limit = 6) {
      let shopProducts = [];
      axios
        .get(`products?shop=${shop.id}&limit=${limit}`)
        .then((response) => {
          response.data.data.filter((product, i) => {
            if (i < limit) {
              shopProducts.push(product);
            }
          });
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
      return shopProducts;
    },
  },
};
</script>
<style lang="css" scoped>
/* @import url(/css/common12d2.css); */

.banner-heading {
  font-weight: 900;
  color: rgb(22, 76, 79);
  font-size: 29px;
  box-sizing: border-box;
  transition: all 400ms ease 0s;
  display: flex;
  flex-direction: column;
}
.banner-heading .banner-heading--1 {
}
.banner-heading .banner-heading--2 {
  font-weight: 300;
}
.banner-heading.banner-title {
  font-weight: 700;
  color: rgb(22, 76, 79);
  font-size: 12px;
  line-height: 1.3;
  box-sizing: border-box;
  margin-top: 0px;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  transition: all 400ms ease 0s;
}
.search-button {
  clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  min-width: 125px;
  color: #262626;
  max-height: 48px;
  font-size: 12px;
  padding: 8px 20px;
  margin-left: -25px;
}
.input-group-text {
  border-radius: 0px 2px;
  background-color: white;
  border-right: none;
}
.search-input {
  height: 48px;
  /* padding: 0 30px 0 48px; */
  border-left: none;
  border-right: none;
  min-width: 35%;
  clip-path: polygon(0% 0%, 98% 0%, 90% 100%, 0% 100%);
}

.card-frame > .card-overlay {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  border-radius: 5px;
}

.card-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 5rem 6rem;
  background-color: #F1F1F1; */
}

@media screen and (max-width: 768px) {
  .card-section {
    padding: 3rem 3rem;
  }

  .search-input {
    width: 500px;
  }
}

@media screen and (max-width: 575px) {
  .card-section {
    padding: 0rem;
    padding-top: 4rem;
  }
  .search-input {
    width: 500px;
  }
}

.card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  }
}

@media screen and (max-width: 768px) {
  .card-container {
    gap: 1.5rem;
  }
}

@media screen and (max-width: 575px) {
  .card-container {
    grid-template-columns: 1fr;
    gap: 1rem;
    row-gap: 0.8rem;
  }
}

.card-frame {
  height: 25rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
}

@media screen and (max-width: 575px) {
  .card-frame {
    height: 10rem;
  }
}

.card-frame[card="fast"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/fast_food.webp");
}

.card-frame[card="fast"] > .card-overlay {
  background-color: rgba(70, 3, 3, 0.6);
}

.card-frame[card="pharmacy"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/pharmacy.webp");
}

.card-frame[card="pharmacy"] > .card-overlay {
  background-color: rgba(60, 3, 70, 0.6);
}

.card-frame[card="electronics"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/electronics.webp");
}

.card-frame[card="electronics"] > .card-overlay {
  background-color: rgba(13, 70, 3, 0.6);
}

.card-frame[card="grocery"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/grocery.webp");
}

.card-frame[card="grocery"] > .card-overlay {
  background-color: rgba(70, 47, 3, 0.6);
}

.card-frame[card="hair_beauty"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/hair_beauty.webp");
}

.card-frame[card="hair_beauty"] > .card-overlay {
  background-color: rgba(70, 3, 3, 0.5);
}

.card-frame[card="house_home"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/house.webp");
}

.card-frame[card="house_home"] > .card-overlay {
  background-color: rgba(38, 95, 109, 0.6);
}

.card-frame[card="construction"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/construction.webp");
}

.card-frame[card="construction"] > .card-overlay {
  background-color: rgba(70, 47, 3, 0.6);
}

.card-frame[card="hardware"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/hardware.webp");
}

.card-frame[card="hardware"] > .card-overlay {
  background-color: rgba(6, 69, 153, 0.6);
}

.card-frame[card="vehicles"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/vehicles.webp");
}

.card-frame[card="vehicles"] > .card-overlay {
  background-color: rgba(211, 42, 42, 0.6);
}

.card-frame[card="solar"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/solar.webp");
}

.card-frame[card="solar"] > .card-overlay {
  background-color: rgba(23, 167, 131, 0.6);
}

.card-frame[card="entertainment"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/entertainment.webp");
}

.card-frame[card="entertainment"] > .card-overlay {
  background-color: rgba(238, 48, 136, 0.6);
}

.card-frame[card="funeral"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/funeral.webp");
}

.card-frame[card="funeral"] > .card-overlay {
  background-color: rgba(63, 68, 65, 0.6);
}

.card-frame[card="gifts"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/treats.webp");
}

.card-frame[card="gifts"] > .card-overlay {
  background-color: rgba(201, 58, 77, 0.6);
}

.card-heading {
  font-size: 1.7em;
  text-align: center;
  padding: 2.5rem 0;
  padding-top: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .card-heading {
    padding: 1.5rem 0;
  }
}

.card-title {
  color: #ffffff;
  font-size: 1.2em !important;
  margin: 0;
  margin-bottom: 0.75rem;
}

@media screen and (max-width: 575px) {
  .card-title {
    font-size: 1em;
  }
}

.card-text {
  padding: 2rem;
  padding-right: 0.5rem;
  flex: 0 0 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
}

.card-text > p {
  padding: 0;
  font-size: 0.96em;
  margin: auto 0;
  color: #fff;
}

@media screen and (max-width: 575px) {
  .card-text > p {
    display: none;
  }
}

.card-text > .btn-primary {
  padding: 10px;
  min-width: 130px;
  font-size: 15px;
}

@media screen and (max-width: 575px) {
  .card-text {
    flex: 1;
    padding: 0.7rem 1rem;
    justify-content: space-between;
  }
}

.card-logo {
  background-color: #fff;
  position: relative;
  flex: 1;
  -webkit-clip-path: polygon(40% 0, 103% 0%, 102% 100%, 2% 100%);
  clip-path: polygon(40% 0, 103% 0%, 102% 100%, 2% 100%);
  height: 100%;
}

.card-logo > img {
  max-width: 60%;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

@media screen and (max-width: 575px) {
  .card-logo {
    display: none;
  }
}

.card-paragraph {
  padding: 0 !important;
}

.promo-runner {
  width: 100%;
  min-height: 6rem;
  display: flex;
  margin: 3rem 0;
  border-radius: 5px;
  padding: 2rem 3rem;
  padding-right: 4rem;
  align-items: center;
  justify-content: space-between;
  background-color: #ad6a00;
  color: #fff !important;
}

@media screen and (max-width: 768px) {
  .promo-runner {
    padding: 1.5rem 1rem;
    flex-direction: column;
    margin: 0.8rem 0;
  }
}

.promo-delivery {
  min-height: 15rem;
  margin: 3.5rem 0;
  border-radius: 5px;
  box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
}

.promo-delivery > .delivery-nav {
  width: 8%;
}

@media screen and (max-width: 575px) {
  .promo-delivery {
    margin: 0.8rem 0;
  }
}

.runner-text {
  margin: 0;
  margin-bottom: 0.4rem;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .runner-text {
    font-size: 1em;
  }
}

@media screen and (max-width: 575px) {
  .runner-text {
    font-size: 0.93em;
  }
}

.runner-info {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.runner-button {
  display: inline-block;
  text-transform: uppercase;
  padding: 0.5rem 0.74rem;
  background-color: white;
  border-radius: 5px;
  color: #000;
}

.runner-button:hover {
  text-decoration: none;
}

@media screen and (max-width: 575px) {
  .runner-button {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }
}

.runner-heading {
  margin-bottom: 1rem;
}

@media screen and (max-width: 575px) {
  .runner-heading {
    font-size: 1.1em;
    width: 100%;
  }
}

.promo-delivery {
  min-height: 15rem;
  margin: 3.5rem 0;
  border-radius: 5px;
  box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
}

.promo-delivery > .delivery-nav {
  width: 8%;
}

@media screen and (max-width: 575px) {
  .promo-delivery {
    margin: 0.8rem 0;
  }
}

.runner-text {
  margin: 0;
  margin-bottom: 0.4rem;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .runner-text {
    font-size: 1em;
  }
}

@media screen and (max-width: 575px) {
  .runner-text {
    font-size: 0.93em;
  }
}

.runner-info {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.runner-button {
  display: inline-block;
  text-transform: uppercase;
  padding: 0.5rem 0.74rem;
  background-color: white;
  border-radius: 5px;
  color: #000;
}

.runner-button:hover {
  text-decoration: none;
}

@media screen and (max-width: 575px) {
  .runner-button {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }
}

.runner-heading {
  margin-bottom: 1rem;
}

@media screen and (max-width: 575px) {
  .runner-heading {
    font-size: 1.1em;
    width: 100%;
  }
}

.delivery-item {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 35rem;
  border-radius: 5px;
  padding: 0 9%;
}

.delivery-item--1 {
  width: 100%;
  /* background-image: url(../images/delivery6efb.webp?5c9178764cdb23596f542cad14f283da); */
  background-position: bottom;
  padding-top: 3rem;
}

.delivery-item--1 .delivery-info {
  color: #000;
}

.delivery-item--1 .delivery-text {
  font-size: 1.4em;
}

.delivery-item--1 .delivery-heading {
  font-size: 1.4em;
  font-family: ClanPro-Medium, Tahoma, Geneva, Verdana, sans-serif;
}

.runner-info > h3,
.runner-info > h5 {
  color: #ffffff !important;
  font-size: 13px;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
}
.runner-info > h3 {
  font-size: 15px;
  font-weight: 700;
}
.show-mobile {
  display: none;
}
@media screen and (max-width: 575px) {
  .promo-delivery {
    min-height: 15rem;
    margin: 3.5rem 0;
    border-radius: 5px;
    box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
  }

  .promo-delivery > .delivery-nav {
    width: 8%;
  }

  @media screen and (max-width: 575px) {
    .promo-delivery {
      margin: 0.8rem 0;
    }
  }

  .runner-text {
    margin: 0;
    margin-bottom: 0.4rem;
    font-weight: 400;
  }

  @media screen and (max-width: 768px) {
    .runner-text {
      font-size: 1em;
    }
  }

  @media screen and (max-width: 575px) {
    .runner-text {
      font-size: 0.93em;
    }
  }

  .runner-info {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .runner-button {
    display: inline-block;
    text-transform: uppercase;
    padding: 0.5rem 0.74rem;
    background-color: white;
    border-radius: 5px;
    color: #000;
  }

  .runner-button:hover {
    text-decoration: none;
  }

  @media screen and (max-width: 575px) {
    .runner-button {
      width: 100%;
      text-align: center;
      margin-top: 1rem;
    }
  }

  .runner-heading {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 575px) {
    .runner-heading {
      font-size: 1.1em;
      width: 100%;
    }
  }

  .delivery-item {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 5px;
    padding: 0 9%;
  }

  .delivery-item--1 {
    width: 100%;
    /* background-image: url(../images/delivery6efb.webp?5c9178764cdb23596f542cad14f283da); */
    background-position: bottom;
    padding-top: 3rem;
  }

  .delivery-item--1 .delivery-info {
    color: #000;
  }

  .delivery-item--1 .delivery-text {
    font-size: 1.4em;
  }

  .delivery-item--1 .delivery-heading {
    font-size: 1.4em;
    font-family: ClanPro-Medium, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  }

  @media screen and (max-width: 575px) {
    .delivery-item--1 {
      padding-top: 28%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .delivery-item--2 {
    padding: 2rem 9%;
    background: #88348f;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 575px) {
    .delivery-item--2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .delivery-item--3 {
    background-color: #1885b0;
    padding: 1.2rem 9%;
  }

  .delivery-item--3 > .delivery-how--heading {
    color: white;
    font-weight: 900;
    font: 1.7em;
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 575px) {
    .delivery-item--3 > .delivery-how--heading {
      font-size: 14px;
    }
  }

  .delivery-item--3 > .delivery-how--list {
    padding-top: 1rem;
    -moz-column-count: 2;
    -moz-column-rule: 2px solid white;
    -webkit-column-count: 2;
    -webkit-column-rule: 2px solid white;
    column-count: 2;
    -webkit-column-gap: 4rem;
    -moz-column-gap: 4rem;
    column-gap: 4rem;
    font-size: 1.24em;
    font-weight: 400;
    color: white;
    line-height: 1.5;
  }

  @media screen and (max-width: 768px) {
    .delivery-item--3 > .delivery-how--list {
      font-size: 1em;
    }
  }

  @media screen and (max-width: 575px) {
    .delivery-item--3 > .delivery-how--list {
      -moz-column-count: 1;
      -moz-column-gap: 0rem;
      -moz-column-rule: none;
      font-size: 14px;
      -webkit-column-count: 1;
      -webkit-column-rule: none;
      -webkit-column-gap: 0rem;
      column-count: 1;
      column-gap: 0rem;
    }
  }

  @media screen and (max-width: 575px) {
    .delivery-item {
      min-height: 45rem;
    }
  }

  .delivery-info {
    flex: 0 0 60%;
    padding: 0 0.3rem;
    display: flex;
    font-family: "ClanPro-Medium", "Segoe UI", Tahoma, Geneva, Verdana,
      sans-serif;
    flex-direction: column;
    color: white;
    width: 60%;
    align-items: flex-start;
    font-weight: 900;
  }

  @media screen and (max-width: 575px) {
    .delivery-info {
      width: 100%;
      padding: 0 0.5rem;
      align-items: flex-start;
      font-size: 14px;
      text-align: center;
    }
  }

  .delivery-heading {
    letter-spacing: 0.08em;
    font-size: 1.8em;
    margin-top: 1rem;
    margin-bottom: 2.3rem;
    .promo-delivery {
      min-height: 15rem;
      margin: 3.5rem 0;
      border-radius: 5px;
      box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
    }

    .promo-delivery > .delivery-nav {
      width: 8%;
    }

    @media screen and (max-width: 575px) {
      .promo-delivery {
        margin: 0.8rem 0;
      }
    }

    .runner-text {
      margin: 0;
      margin-bottom: 0.4rem;
      font-weight: 400;
    }

    @media screen and (max-width: 768px) {
      .runner-text {
        font-size: 1em;
      }
    }

    @media screen and (max-width: 575px) {
      .runner-text {
        font-size: 0.93em;
      }
    }

    .runner-info {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .runner-button {
      display: inline-block;
      text-transform: uppercase;
      padding: 0.5rem 0.74rem;
      background-color: white;
      border-radius: 5px;
      color: #000;
    }

    .runner-button:hover {
      text-decoration: none;
    }

    @media screen and (max-width: 575px) {
      .runner-button {
        width: 100%;
        text-align: center;
        margin-top: 1rem;
      }
    }

    .runner-heading {
      margin-bottom: 1rem;
    }

    @media screen and (max-width: 575px) {
      .runner-heading {
        font-size: 1.1em;
        width: 100%;
      }
    }

    .delivery-item {
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      min-height: 45rem;
      border-radius: 5px;
      padding: 0 9%;
    }

    .delivery-item--1 {
      width: 100%;
      /* background-image: url(../images/delivery6efb.webp?5c9178764cdb23596f542cad14f283da); */
      background-position: bottom;
      padding-top: 3rem;
    }

    .delivery-item--1 .delivery-info {
      color: #000;
    }

    .delivery-item--1 .delivery-text {
      font-size: 1.4em;
    }

    .delivery-item--1 .delivery-heading {
      font-size: 1.4em;
      font-family: ClanPro-Medium, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
    }

    @media screen and (max-width: 575px) {
      .delivery-item--1 {
        padding-top: 28%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .delivery-item--2 {
      padding: 2rem 9%;
      background: #88348f;
      display: flex;
      align-items: center;
    }

    @media screen and (max-width: 575px) {
      .delivery-item--2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    .delivery-item--3 {
      background-color: #1885b0;
      padding: 1.2rem 9%;
    }

    .delivery-item--3 > .delivery-how--heading {
      color: white;
      font-weight: 900;
      font: 1.7em;
      margin-bottom: 0.5rem;
    }

    @media screen and (max-width: 575px) {
      .delivery-item--3 > .delivery-how--heading {
        font-size: 14px;
      }
    }

    .delivery-item--3 > .delivery-how--list {
      padding-top: 1rem;
      -moz-column-count: 2;
      -moz-column-rule: 2px solid white;
      -webkit-column-count: 2;
      -webkit-column-rule: 2px solid white;
      column-count: 2;
      -webkit-column-gap: 4rem;
      -moz-column-gap: 4rem;
      column-gap: 4rem;
      font-size: 1.24em;
      font-weight: 400;
      color: white;
      line-height: 1.5;
    }

    @media screen and (max-width: 768px) {
      .delivery-item--3 > .delivery-how--list {
        font-size: 1em;
      }
    }

    @media screen and (max-width: 575px) {
      .delivery-item--3 > .delivery-how--list {
        -moz-column-count: 1;
        -moz-column-gap: 0rem;
        -moz-column-rule: none;
        font-size: 14px;
        -webkit-column-count: 1;
        -webkit-column-rule: none;
        -webkit-column-gap: 0rem;
        column-count: 1;
        column-gap: 0rem;
      }
    }

    @media screen and (max-width: 575px) {
      .delivery-item {
        min-height: 45rem;
      }
    }

    .delivery-info {
      flex: 0 0 60%;
      padding: 0 0.3rem;
      display: flex;
      font-family: "ClanPro-Medium", "Segoe UI", Tahoma, Geneva, Verdana,
        sans-serif;
      flex-direction: column;
      color: white;
      width: 60%;
      align-items: flex-start;
      font-weight: 900;
    }

    @media screen and (max-width: 575px) {
      .delivery-info {
        width: 100%;
        padding: 0 0.5rem;
        align-items: flex-start;
        font-size: 14px;
        text-align: center;
      }
    }

    .delivery-heading {
      letter-spacing: 0.08em;
      font-size: 1.8em;
      margin-top: 1rem;
      margin-bottom: 2.3rem;
    }

    @media screen and (max-width: 768px) {
      .delivery-heading {
        font-size: 1.6em;
        margin: 0;
        margin-bottom: 1.2rem;
      }
    }

    @media screen and (max-width: 575px) {
      .delivery-heading {
        font-size: 17px;
        font-weight: 900;
        width: 100%;
      }
    }

    .delivery-text {
      font-weight: 400;
      font-size: 1em;
      margin-bottom: 0.6rem;
      letter-spacing: 0.032em;
    }

    @media screen and (max-width: 575px) {
      .delivery-text {
        margin-bottom: 1rem;
        font-size: 12px;
        width: 100%;
      }
    }

    .delivery-image {
      position: absolute;
      top: 50%;
      right: 15%;
      transform: translate(20%, -50%);
      z-index: 5;
      width: 36%;
      margin: 0 auto;
    }

    .delivery-image > img {
      width: 100%;
    }

    @media screen and (max-width: 575px) {
      .delivery-image {
        width: 65%;
        margin-top: 0.5rem;
        transform: unset;
        position: static;
      }
    }

    @media screen and (max-width: 575px) {
      .delivery-button {
        margin-top: 1rem;
        text-align: center;
      }
    }

    .delivery-highlight {
      font-weight: 900;
    }
  }

  @media screen and (max-width: 768px) {
    .delivery-heading {
      font-size: 1.6em;
      margin: 0;
      margin-bottom: 1.2rem;
    }
  }

  .delivery-text {
    font-weight: 400;
    font-size: 1em;
    margin-bottom: 0.6rem;
    letter-spacing: 0.032em;
  }

  @media screen and (max-width: 575px) {
    .delivery-text {
      margin-bottom: 1rem;
      font-size: 12px;
      width: 100%;
    }
  }

  .delivery-image {
    position: absolute;
    top: 50%;
    right: 15%;
    transform: translate(20%, -50%);
    z-index: 5;
    width: 36%;
    margin: 0 auto;
  }

  .delivery-image > img {
    width: 100%;
  }

  @media screen and (max-width: 575px) {
    .delivery-image {
      width: 65%;
      margin-top: 0.5rem;
      transform: unset;
      position: static;
    }
  }

  @media screen and (max-width: 575px) {
    .delivery-button {
      margin-top: 1rem;
      text-align: center;
    }
  }

  .delivery-highlight {
    font-weight: 900;
  }

  .delivery-item--1 {
    padding-top: 28%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.delivery-item--2 {
  padding: 2rem 9%;
  background: #88348f;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 575px) {
  .delivery-item--2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.delivery-item--3 {
  background-color: #1885b0;
  padding: 1.2rem 9%;
}

.delivery-item--3 > .delivery-how--heading {
  color: white;
  font-weight: 900;
  font: 1.7em;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 575px) {
  .delivery-item--3 > .delivery-how--heading {
    font-size: 14px;
  }
}

.delivery-item--3 > .delivery-how--list {
  padding-top: 1rem;
  -moz-column-count: 2;
  -moz-column-rule: 2px solid white;
  -webkit-column-count: 2;
  -webkit-column-rule: 2px solid white;
  column-count: 2;
  -webkit-column-gap: 4rem;
  -moz-column-gap: 4rem;
  column-gap: 4rem;
  font-size: 1.24em;
  font-weight: 400;
  color: white;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .delivery-item--3 > .delivery-how--list {
    font-size: 1em;
  }
}
.shop-direct {
  display: none;
}
@media screen and (max-width: 575px) {
  .delivery-item--3 > .delivery-how--list {
    -moz-column-count: 1;
    -moz-column-gap: 0rem;
    -moz-column-rule: none;
    font-size: 14px;
    -webkit-column-count: 1;
    -webkit-column-rule: none;
    -webkit-column-gap: 0rem;
    column-count: 1;
    column-gap: 0rem;
  }
}

@media screen and (max-width: 575px) {
  .delivery-item {
    padding: 2rem 0.5rem;
    min-height: 35rem;
  }
  .delivery-info > h2 {
    font-size: 21px;
    text-align: center;
    width: 100%;
  }
  .delivery-info > h5 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  .delivery-image {
    max-width: 40%;
  }
  .shop-direct {
    display: inline;
  }

  .banner-content {
    display: none;
  }
}

.delivery-info {
  flex: 0 0 60%;
  padding: 0 0.3rem;
  display: flex;
  font-family: "ClanPro-Medium", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  flex-direction: column;
  color: white;
  width: 60%;
  align-items: flex-start;
  font-weight: 900;
}

@media screen and (max-width: 575px) {
  .delivery-info {
    width: 100%;
    padding: 0 0.5rem;
    align-items: flex-start;
    font-size: 14px;
    text-align: center;
  }
  .delivery-info > h5 {
    text-align: center;
    width: 100%;
  }
}

.delivery-heading {
  letter-spacing: 0.08em;
  font-size: 1.8em;
  margin-top: 1rem;
  margin-bottom: 2.3rem;
}

@media screen and (max-width: 768px) {
  .delivery-heading {
    font-size: 1.6em;
    margin: 0;
    margin-bottom: 1.2rem;
  }
}

@media screen and (max-width: 575px) {
  .delivery-heading {
    font-size: 17px;
    font-weight: 900;
    width: 100%;
  }
}

.delivery-text {
  font-weight: 400;
  font-size: 1em;
  margin-bottom: 0.6rem;
  letter-spacing: 0.032em;
}

@media screen and (max-width: 575px) {
  .delivery-text {
    margin-bottom: 1rem;
    font-size: 12px;
    width: 100%;
  }
}

.delivery-image {
  position: absolute;
  top: 50%;
  right: 15%;
  transform: translate(20%, -50%);
  z-index: 5;
  width: 28%;
  margin: 0 auto;
}

.delivery-image > img {
  width: 100%;
}

@media screen and (max-width: 575px) {
  .delivery-image {
    width: 65%;
    margin-top: 0.5rem;
    transform: unset;
    position: static;
  }
  .show-mobile {
    display: block;
  }
}

@media screen and (max-width: 575px) {
  .delivery-button {
    margin-top: 1rem;
    text-align: center;
  }
  .carousel-mobile-image {
    width: 100%;
    object-fit: cover;
  }
}

.delivery-highlight {
  font-weight: 900;
}

/*search box css start here*/
.search-sec {
  padding: 2rem;
  top: -500px;
}
.search-slt {
  display: block;
  width: 100%;
  /* font-size: 0.875rem; */
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  /* height: calc(3rem + 2px) !important; */
  border-radius: 0;
}
.wrn-btn {
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  height: 100% !important;
  border-radius: 0;
  color: black;
  border: none;
  background-color: #f9a51e;
}
@media (min-width: 992px) {
  .search-sec {
    /* position: relative; */
    margin-top: -100px;
    z-index: 10;
    background: black;
  }

  .slider-image {
    height: 600px !important;
  }
}

@media (max-width: 992px) {
  .search-sec {
    background: black;
  }

  .slider-image {
    height: 600px !important;
  }
}
.slider-image {
  height: 600px !important;
}

@media (max-width: 600px) {
  .slider-image {
    height: 100% !important;
  }
}
</style>
